import { useState, useEffect } from "react";

import "./App.scss";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import FreshbooksAPI from "./components/FreshbooksAPI.js";
import ActiveProjects from "./components/ActiveProjects.js";
import LoginLink from "./components/LoginLink.js";

async function freshbooks_login(code) {
  const WORKER_URL = "https://freshbooks-datamade-auth.ftgregg.workers.dev";

  // remove ?code=... from URL
  const path =
    window.location.pathname +
    window.location.search.replace(/\bcode=\w+/, "").replace(/\?$/, "");
  window.history.pushState({}, "", path);

  try {
    const response = await fetch(WORKER_URL, {
      method: "POST",
      mode: "cors",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({ code }),
    });

    const result = await response.json();

    if (result.error) {
      return alert(JSON.stringify(result, null, 2));
    } else {
      return result.token;
    }
  } catch (error) {
    console.log(error);
  }
}

function App() {
  const [token, setToken] = useState(null);
  const [freshbooksClient, setFreshbooksClient] = useState(null);

  useEffect(() => {
    async function init() {
      const code = new URL(window.location.href).searchParams.get("code");

      if (code) {
        setToken(await freshbooks_login(code));
      }
    }
    init();
  }, []);

  useEffect(() => {
    if (token !== null) {
      setFreshbooksClient(new FreshbooksAPI(token));
    }
  }, [token]);

  return (
    <Container className="p-3">
      <Row>
        <Col>
          <h1>DataMade Project Status</h1>
          {freshbooksClient ? (
            <>
              <LoginLink freshbooksClient={freshbooksClient} />
              <ActiveProjects freshbooksClient={freshbooksClient} />
            </>
          ) : (
            <p id="signed-out">
              <a href="https://freshbooks-datamade-auth.ftgregg.workers.dev">
                Login with Freshbooks
              </a>
            </p>
          )}
        </Col>
      </Row>
    </Container>
  );
}

export default App;
