import { load as yamlLoad } from "js-yaml";

class FreshbooksAPI {
  constructor(token) {
    this.token = token;
    this.businessId = "1857498";
    this.accountId = "wyO13";
  }

  async me() {
    const data = await this.fetch_json(
      "https://api.freshbooks.com/auth/api/v1/users/me",
    );
    return data.response;
  }

  async active_projects() {
    const project_url = `https://api.freshbooks.com/projects/business/${this.businessId}/projects?`;

    const params = new URLSearchParams({
      complete: false,
      internal: false,
      per_page: 100,
    });

    const data = await this.fetch_json(project_url + params);

    for (const project of data.projects) {
      if (project.description) {
        project.extraConfig = yamlLoad(project.description);
      } else {
        project.extraConfig = {};
      }
      // as the owner of the Freshbooks account, Derek can't be
      // made the project manager for any project. So we use
      // a setting in the project description to do it.
      // See #26 for more details about this issue.
      if (project.extraConfig.derek_pm) {
        project.project_manager_id = 1919262;
      } else if (project.extraConfig.forest_pm) {
        project.project_manager_id = 1919278;
      }
    }
    return data;
  }

  async active_user_projects() {
    const currentUser = await this.me();

    const owned_groups = new Set(
      currentUser.groups
        .filter((group) => group.role === "owner")
        .map((group) => group.group_id),
    );

    const active_projects = await this.active_projects();

    // if the project has a project manager, use that to
    // determine who owns the project. if not, use the "owner" (i.e.
    // the person who first created the project
    active_projects.projects = active_projects.projects.filter(
      (project) =>
        project.project_manager_id === currentUser.id ||
        (project.project_manager_id === null &&
          owned_groups.has(project.group_id)),
    );

    return active_projects;
  }

  async team_rates(projectId) {
    const rates_url = `https://api.freshbooks.com/comments/business/${this.businessId}/project/${projectId}/team_member_rates`;

    const data = await this.fetch_json(rates_url);

    return data;
  }

  async project_time(projectId) {
    const time_entries_url = `https://api.freshbooks.com/comments/business/${this.businessId}/time_entries?`;

    const params = new URLSearchParams({
      project_id: projectId,
      team: true,
      include_total_logged_per_team_member: true,
    });

    const data = await this.fetch_json(time_entries_url + params);

    return data;
  }

  async project_time_entries(projectId) {
    const time_entries_url = `https://api.freshbooks.com/comments/business/${this.businessId}/time_entries?`;

    const params = new URLSearchParams({
      project_id: projectId,
      team: true,
    });

    const data = await this.fetch_json(time_entries_url + params);

    return data;
  }

  async project_invoices(projectId) {
    const invoices_url = `https://api.freshbooks.com/accounting/account/${this.accountId}/invoices/invoices?`;

    const params = new URLSearchParams({
      "search[modern_projectid]": projectId,
      "include[]": "project_total_amounts",
      per_page: 100,
    });

    const data = await this.fetch_json(invoices_url + params);

    return data;
  }

  async fetch_json(url, init = { headers: {} }) {
    const api_headers = {
      "Content-Type": "application/json",
      "Api-Version": "alpha",
      Authorization: `Bearer ${this.token}`,
    };
    init.headers = {
      ...init.headers,
      ...api_headers,
    };

    const TIME_TO_LIVE = 5000;

    const item = sessionStorage.getItem(url);

    if (item) {
      const hydrated = JSON.parse(item);
      if (Date.now() - hydrated.timestamp < TIME_TO_LIVE) {
        return hydrated.json;
      }
    }

    const response = await fetch(url, init);

    if (response.status === 200) {
      const data = await response.json();

      sessionStorage.setItem(
        url,
        JSON.stringify({ timestamp: Date.now(), json: data }),
      );
      return data;
    } else {
      return response;
    }
  }
}

export default FreshbooksAPI;
