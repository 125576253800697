import hash from "object-hash";
import { useState, useEffect } from "react";

import ProgressBar from "react-bootstrap/ProgressBar";
import Table from "react-bootstrap/Table";

import ProjectPhase from "./ProjectPhase.js";
import github_icon from "../images/github.svg";
import google_drive_icon from "../images/google_drive.svg";
import project_board_icon from "../images/project_board.svg";

const icons = {
  project_folder: { img: google_drive_icon, alt_text: "Project Folder" },
  github_repo: { img: github_icon, alt_text: "Code Repository" },
  project_board: { img: project_board_icon, alt_text: "Project Board" },
};

export default function ProjectGroup({
  rawProjectGroup,
  freshbooksClient,
  setRichProjects,
}) {
  const [projectGroup, setProjectGroup] = useState(undefined);

  useEffect(() => {
    async function init() {
      const project = structuredClone(rawProjectGroup);

      const projects = project.projects;
      for (const phase of projects) {
        let extendedPhase = new ProjectPhase(phase, freshbooksClient);
        const rates = await extendedPhase.rates();
        phase.max_rate = Math.max(...rates);

        phase.cost = await extendedPhase.cost();
        phase.hoursSpent = await extendedPhase.hoursSpent();

        if (phase.cost > 0) {
          phase.average_rate = phase.cost / phase.hoursSpent;
        } else {
          phase.average_rate = rates.reduce((sum, r) => sum + r) / rates.length;
        }

        phase.budget = (phase.budget / 3600) * phase.max_rate;
        phase.remaining = phase.budget - phase.cost;
        phase.progress = (100 * (phase.cost / phase.budget)).toFixed(0);

        if (phase.description) {
          phase.links = Object.entries(phase.extraConfig).filter(
            ([key, value]) => key in icons,
          );
        } else {
          phase.links = [];
        }
      }

      project.budgetTotal = projects.reduce(
        (sum, p) => sum + Number(p.budget),
        0,
      );
      project.spentTotal = projects.reduce((sum, p) => sum + Number(p.cost), 0);
      project.remainingTotal = projects.reduce(
        (sum, p) => sum + Number(p.remaining),
        0,
      );

      if (project.spentTotal) {
        project.avgRate =
          project.spentTotal /
          projects.reduce((sum, p) => sum + Number(p.hoursSpent), 0);
      } else {
        project.avgRate =
          projects.reduce((sum, r) => sum + r.average_rate) / projects.length;
      }

      project.hoursLeft = (project.remainingTotal / project.avgRate).toFixed();
      project.groupProgress = (
        100 *
        (project.spentTotal / project.budgetTotal)
      ).toFixed(0);

      project.progress_status = "default";
      if (project.groupProgress > 100) {
        project.progress_status = "danger";
      }

      project.links = [];
      for (const key of Object.keys(icons)) {
        const links = project.projects
          .map((phase) =>
            phase.links.find(([type, link]) => type === key && link),
          )
          .filter((link) => link);
        const unique_links = Array.from(
          new Set(links.map((link) => JSON.stringify(link))),
        ).map((json_link) => JSON.parse(json_link));
        if (unique_links.length === 1) {
          const [unique_link] = unique_links;
          project.links.push(unique_link);
          for (const phase of project.projects) {
            phase.links = phase.links.filter(([type, link]) => type !== key);
          }
        }
      }
      setRichProjects((previousProjects) => [...previousProjects, project]);
      setProjectGroup(project);
    }
    init();
  }, [rawProjectGroup, freshbooksClient, setRichProjects]);

  return projectGroup ? (
    <div key={`group-${hash(projectGroup)}`} className="card my-2">
      <div className="card-body">
        <h5 className="card-title">
          {projectGroup.group_title}
          <Links links={projectGroup.links} />
        </h5>
        <Table striped bordered hover size="sm">
          <thead>
            <tr>
              <th style={{ width: "30%" }}>Phase</th>
              <th className="text-right">Budget</th>
              <th className="text-right">Spent</th>
              <th className="text-right">Remaining</th>
              <th className="text-right">Avg rate</th>
              <th className="text-right">Hours left</th>
              <th style={{ width: "15%" }}>Progress</th>
            </tr>
          </thead>
          <tbody>
            {projectGroup.projects.map((p) => (
              <ProjectRow project={p} key={`project-${p.id}`} />
            ))}
          </tbody>
          <tfoot>
            <tr>
              <th>Total</th>
              <th className="text-right">
                {moneyfmt.format(projectGroup.budgetTotal)}
              </th>
              <th className="text-right">
                {moneyfmt.format(projectGroup.spentTotal)}
              </th>
              <th className="text-right">
                {moneyfmt.format(projectGroup.remainingTotal)}
              </th>
              <th className="text-right">
                {moneyfmt.format(projectGroup.avgRate)}
              </th>
              <th className="text-right">{projectGroup.hoursLeft}</th>
              <th>
                {projectGroup.groupProgress !== "NaN" &&
                projectGroup.groupProgress !== 0 ? (
                  <ProgressBar
                    variant={projectGroup.progress_status}
                    now={projectGroup.groupProgress}
                    label={`${projectGroup.groupProgress}%`}
                  />
                ) : (
                  <></>
                )}
              </th>
            </tr>
          </tfoot>
        </Table>
      </div>
    </div>
  ) : (
    <></>
  );
}

function ProjectRow({ project }) {
  return project.max_rate !== undefined ? (
    <tr>
      <td>
        <a
          href={`https://my.freshbooks.com/#/project/${project.id}`}
          target="_blank"
          rel="noreferrer"
        >
          {project.phase_title}
        </a>
        <Links links={project.links} />
      </td>
      <td className="text-right">{moneyfmt.format(project.budget)}</td>
      <td className="text-right">{moneyfmt.format(project.cost)}</td>
      <td className="text-right">{moneyfmt.format(project.remaining)}</td>
      <td className="text-right">{moneyfmt.format(project.average_rate)}</td>
      <td className="text-right">
        {(project.remaining / project.average_rate).toFixed()}
      </td>
      <td>
        {project.progress !== "NaN" && project.budget !== 0 ? (
          <ProgressBar
            variant="info"
            now={project.progress}
            label={`${project.progress}%`}
          />
        ) : (
          <></>
        )}
      </td>
    </tr>
  ) : (
    <></>
  );
}

function Links({ links }) {
  return (
    <>
      {links.length > 0 && <>&nbsp;&nbsp;&nbsp;</>}
      {links.map(([type, url]) => (
        <span key={hash(url)}>
          <a href={url} target="_blank" rel="noreferrer">
            <img
              src={icons[type].img}
              style={{ height: "1em" }}
              alt={icons[type].alt_text}
              title={icons[type].alt_text}
            />
          </a>{" "}
        </span>
      ))}
    </>
  );
}

const moneyfmt = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  maximumFractionDigits: 0,
});
