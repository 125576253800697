class ProjectPhase {
  constructor(projectResult, freshbooksClient) {
    Object.assign(this, projectResult);
    this.freshbooksClient = freshbooksClient;
  }

  async rates() {
    const result = await this.freshbooksClient.team_rates(this.id);
    return result.project_team_member_rates.map((member) =>
      Number(member.rate),
    );
  }

  async hoursSpent() {
    const timeEntries = await this.freshbooksClient.project_time(this.id);
    return timeEntries.meta.total_logged / 3600;
  }

  async cost() {
    const rates = await this.freshbooksClient.team_rates(this.id);
    const memberRates = new Map(
      rates.project_team_member_rates.map((member) => [
        member.identity_id,
        parseInt(member.rate),
      ]),
    );
    const timeEntries = await this.freshbooksClient.project_time(this.id);

    const totalCost = timeEntries.meta.total_logged_per_team_member.reduce(
      (partialSum, entry) =>
        partialSum +
        (entry.total_logged / 3600) * memberRates.get(entry.identity_id),
      0,
    );
    return totalCost;
  }

  async invoiced() {
    const invoices = await this.freshbooksClient.project_invoices(this.id);

    const totalInvoiced = invoices.response.result.invoices
      .map((invoice) => invoice.project_total_amounts)
      .flat()
      .filter((project_total) => project_total.modern_project_id === this.id)
      .reduce(
        (partialSum, project_total) =>
          partialSum + parseFloat(project_total.total_amount.amount),
        0,
      );
    return totalInvoiced;
  }
}

export default ProjectPhase;
