import hash from "object-hash";
import { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";

import ProjectGroup from "./ProjectGroup.js";
import CompanySummary from "./CompanySummary.js";

export default function ActiveProjects({ freshbooksClient }) {
  const [projectGroups, setProjectGroups] = useState([]);
  const [filterType, setFilterType] = useState("project_manager");
  const [richProjects, setRichProjects] = useState([]);

  useEffect(() => {
    async function init() {
      let result;
      if (filterType === "project_manager") {
        result = await freshbooksClient.active_user_projects();
      } else {
        result = await freshbooksClient.active_projects();
      }

      let projects = result.projects;

      // sort by project title
      projects.sort((a, b) => {
        let fa = a.title.toLowerCase(),
          fb = b.title.toLowerCase();

        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      });

      // group freshbooks projects
      let projectGroups = [];
      for (const p of projects) {
        let [group_name, phase] = p.title.split(" - ", 2);
        p.phase_title = phase;

        // add a phase to an existing group
        let found_group = projectGroups.find(
          (o) => o.group_title === group_name,
        );
        if (found_group !== undefined) {
          found_group.projects.push(p);
        } else {
          projectGroups.push({
            group_title: group_name,
            projects: [p],
          });
        }
      }
      setRichProjects([]);
      setProjectGroups(projectGroups);
    }
    init();
  }, [freshbooksClient, filterType]);

  function toggleFilter() {
    if (filterType === "project_manager") {
      setFilterType("everything");
    } else {
      setFilterType("project_manager");
    }
  }

  return (
    <>
      <Button onClick={toggleFilter} className="mb-3">
        Switch to
        {filterType === "project_manager" ? " Everything" : " Project Manager"}
      </Button>
      {richProjects.length > 0 &&
      richProjects.length === projectGroups.length ? (
        <CompanySummary richProjects={richProjects} filterType={filterType} />
      ) : (
        <p className="pending animate-flicker">Loading Freshbooks data...</p>
      )}
      {projectGroups.length > 0 &&
        projectGroups.map((g) => {
          return (
            <ProjectGroup
              key={hash(g)}
              rawProjectGroup={g}
              freshbooksClient={freshbooksClient}
              setRichProjects={setRichProjects}
            />
          );
        })}
    </>
  );
}
