import { useState, useEffect } from "react";

export default function LoginLink({ freshbooksClient }) {
  const [username, setUsername] = useState("");

  useEffect(() => {
    async function init() {
      const result = await freshbooksClient.me();
      setUsername(result.first_name + " " + result.last_name);
    }
    init();
  }, [freshbooksClient]);

  return <>{username && <p>Logged in as {username}</p>}</>;
}
